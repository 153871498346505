import React, { useContext, useEffect } from "react"
import "twin.macro"
import { graphql } from "gatsby"
import DynamicZone from "../components/organisms/dynamic-zone"
import {
  ACTIONS,
  createAction,
  GlobalDispatchContext,
} from "../utils/global-context"
import { useMediaQuery } from "../utils/use-media-query"
import { navigate } from "@reach/router"
import DrawerSingleStory from "../components/organisms/episodes-drawer/drawer-single-story"
import Seo from "@atoms/seo"

const StoryPage = ({ data: { strapiStory: story } }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const isXL = useMediaQuery("(min-width: 1024px)")
  const seo = {
    meta_title: story.name,
    ...story.seo,
  }

  useEffect(() => {
    const episodeIndex = parseInt(
      new URLSearchParams(window.location.search).get("play")
    )
    if (isFinite(episodeIndex)) {
      const episode = story.episodes?.[episodeIndex - 1]
      if (episode && episode.vimeo_data) {
        dispatch({
          type: ACTIONS.setVideo,
          payload: {
            fallback_url: story.fallback_url,
            url: episode.vimeo_url,
            ...episode.vimeo_data,
          },
        })
      } else {
        navigate(window.location.href.split("?")[0])
      }
    }
  })
  useEffect(() => {
    dispatch(createAction(ACTIONS.selectStory, story.id))
    return () => dispatch(createAction(ACTIONS.selectStory, null))
  })
  return (
    <>
      <Seo {...seo} />
      <div tw="bg-white relative lg:flex">
        <div tw="flex-grow">
          <DynamicZone
            components={story.containers}
            context={{ fallback_url: story?.fallback_url }}
          />
        </div>
        {isXL && (
          <div tw="relative flex-grow-0" className="tear-l-white">
            <div
              tw="sticky top-0 overflow-y-auto max-h-screen pt-6 xxl:px-2"
              css={`
                width: 224px;
                @media (min-width: 1440px) {
                  width: 244px;
                }
              `}
            >
              <DrawerSingleStory story={story} />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export const query = graphql`
  query Story($id: Int!) {
    strapiStory(strapiId: { eq: $id }) {
      id
      name
      fallback_url
      containers {
        ...DynamicZoneFragment
      }
      episodes {
        ...EpisodeSummaryFragment
        locale
        title
        description
      }
      seo {
        ...SeoFragment
      }
    }
  }
`

export default StoryPage
